<template>
  <div class="contact">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">联系我们</h6>
        <div class="line"></div>
        <p class="banner_content">CONTACT US</p>
      </div>
    </div>
    <div class="con">
      <!-- <div class="con_container"> -->
      <div class="row container">
        <div class="col-sm-12 col-md-4 row-position">
          <img src="../assets/contactImages/address.png" class="card-img-left">
          <div class="card-body">
            <h5 class="card-title">公司地址</h5>
            <p class="card-text">西安市碑林区南二环东段交大出版传媒大厦</p>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 row-position">
          <img src="../assets/contactImages/email.png" class="card-img-left">
          <div class="card-body">
            <h5 class="card-title">公司邮箱</h5>
            <p class="card-text">471029850@qq.com</p>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 row-position">
          <img src="../assets/contactImages/tel.png" class="card-img-left">
          <div class="card-body">
            <h5 class="card-title">公司电话</h5>
            <p class="card-text">029-82569656</p>
          </div>
        </div>
      </div>
      <div class="map">
        <img src="../assets/contactImages/map.jpg" class="pc-image">
        <img src="../assets/contactImages/map_phone.jpg" class="mobile-image">
      </div>
      <div class="container">
        <div class="text">
          有任何问题，欢迎在此留言并留下您的联系方式我们将尽快回复您
        </div>
        <el-form status-icon :rules="rules" ref="ruleForm" :label-position="labelPosition" label-width="80px"
          :model="ruleForm">
          <div class="from">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="备注" prop="remark" >
            <el-input class="textarea_input" type="textarea" v-model="ruleForm.remark" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    /* 手机号 */
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error('请输入电话号码')
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) //转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          //判断是否为数字
          value = value.toString() //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      labelPosition: 'right',
      ruleForm: {
        name: '',
        phone: '',
        email: '',
        remark: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' },
          { pattern: /^[^\d]+$/, message: '姓名不能包含数字', trigger: 'blur' } // 新增的验证规则
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        remark: [
          { required: true, message: '请填写具体内容', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    submitForm() {
      //如果验证失败，停止函数执行
      if (!this.ruleForm.phone || !this.ruleForm.name || !this.ruleForm.email) {
        window.alert("请填写具体信息");
        return; // 如果任何字段为空，请提前退出该函数。
      }
      axios
          .post('https://hcky.medatacs.top/cdmsProFeedback/insert', this.ruleForm).then((res) => {
          console.log(res);
          if (res.data.resCode === 200) {
            window.alert("提交成功");
            window.location.reload();
          } else {
            window.alert(res.data.resMsg);
          }
        })
        .catch((error) => {
          console.error('Error submitting form:', error);
          window.alert("提交失败，请稍后重试");
        });
    }



  }
}
</script>

<style scoped lang="less">
/* 移动端样式 */
@media screen and (max-width: 767px) {
  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/contactImages/contact_phone.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .row {
    width: 80%;
    margin: 0 auto;
   
  }

  .row .col-sm-12 {
    margin-bottom: 15px;
  }

  .row .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .map {
    display: flex;
    justify-content: center;
    align-items: center;

    .pc-image {
      display: none;
    }

    .mobile-image {
      display: block;
      width: 361px;
      margin: auto;
    }
  }

  .text {
    text-align: center;
    font-size: 16px;
    width: 100%;
    height: 116px;
    line-height: 60px;
    margin: auto;
  }

  .el-form {
    text-align: center;

    .textarea_input {
      width:100%;
    }

    .el-form-item {
      width: 100%;
    }
    .el-button{
      margin-right: 55px;
    }
  }
}


/* PC端样式 */
@media screen and (min-width: 768px) {
  .contact {
    height: 1710px;
  }

  .banner {
    width: 100%;
    height: 600px;
    background-image: url(../assets/contactImages/contact_banner.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333;
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .con {
    // width: 100%;
    // height: 1200px;
    // background-color: #fff;

    // .con_container {
    //   margin: auto;
    //   width: 80%;
    // }

    .row {
      margin: auto;

      .row-position {
        display: flex;
        justify-content: space-evenly;
        padding-top: 35px;

        img {
          width: 37px;
          height: 37px;
          margin-top: 20px;
          border: 1px dashed gray;
        }

        .card-title {
          font-size: 25px;

          .card-text {
            font-size: 14px;
          }
        }
      }
    }



    .text {
      text-align: center;
      font-size: 25px;
      width: 530px;
      height: 150px;
      line-height: 60px;
      margin: auto;
    }

    .el-form {
      margin-left: 80px;
      text-align: center;

      .from {
        display: flex;
        justify-content: space-evenly;
        width: 878px;
      }

      .textarea_input {
        width: 800px;
      }

      .el-form-item {
        width: 800px;
      }
    }



  }

  .map {
    display: flex;
    justify-content: center;
    align-items: center;

    .pc-image {
      display: block;
      /* 默认显示PC图片 */
      width: auto;
    }

    .mobile-image {
      display: none;
      /* 默认隐藏移动端图片 */

    }
  }

}</style >