<template>
  <div class="about">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">关于我们</h6>
        <div class="line"></div>
        <p class="banner_content">ABOUT US</p>
      </div>
    </div>
    <div class="con" style="margin-top: 50px;">
      <div class="container">
        <div class="row">
          <!-- 右侧文字列，占据8列（col-md-8表示在中等屏幕及以上宽度占据8列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <div class="top">
              <h6 class="top_text">COMPANY PROFILE</h6>
              <p class="top_content">公司简介</p>
              <div class="top_line"></div>
            </div>
            <div class="bottom">
              <h6 class="bottom_title">西安鸿创医学科技有限公司</h6>
              <p class="bottom_text">
                西安鸿创医学科技有限公司是一家专注于真实世界研究及临床科研管理的新兴公司，公司致力于为临床科研人员提供专业的临床研究和科学学术服务，让用户更便捷的开展临床研究工作。由鸿创团队自主研发的临床科研开放管理平台，为医疗机构、医药企业和政府机构提供了项目管理、数据采集、质量控制、数据管理、数据分析、可视化与共享等功能，提高了数据采集效率，让您省时省力又省钱。
              </p>
            </div>
          </div>
          <!-- 左侧图片列，占据4列（col-md-4表示在中等屏幕及以上宽度占据4列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <img src="../assets/aboutImages/about_con2.jpg" alt="Your Image" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <!-- 左侧图片列，占据4列（col-md-4表示在中等屏幕及以上宽度占据4列，在小屏幕下会占据整行） -->
          <div class="col-md-6 ">
            <img src="../assets/aboutImages/about_con.jpg" alt="Your Image" class="img-fluid">
          </div>
          <!-- 右侧文字列，占据8列（col-md-8表示在中等屏幕及以上宽度占据8列，在小屏幕下会占据整行） -->
          <div class="col-md-6 ">
            <div class="top">
              <h6 class="top_text">CORE TEAM</h6>
              <p class="top_content">核心团队</p>
              <div class="top_line"></div>
            </div>
            <div class="bottom">
              <p class="bottom_text">
                医学、统计学与计算机领域人才，组成跨界团队，深度融合开发专业创新产品。公司拥有数十名从事流行病研究、循证医学研究、临床研究、卫生统计学研究的教授、副教授、博士团队，可提供专业的一站式科研服务。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aptitude">
      <div class="container">
        <div class="container_text">
          <h6 class="aptitude_text">APTITUDE</h6>
          <p class="aptitude_content">证书资质</p>
          <div class="container_text_line"></div>
        </div>
        <el-carousel :interval="2000" :height="isMobile ? '' : '550px'" :indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <img src="../assets/aboutImages/aptitude1.jpg" alt=""
              :style="{ height: isMobile ? 'auto' : '500px', width: '800px', border: '1px dashed rgb(148, 145, 145)' }">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/aboutImages/aptitude2.jpg" alt=""
              :style="{ height: isMobile ? 'auto' : '500px', width: '800px', border: '1px dashed rgb(148, 145, 145)' }">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/aboutImages/aptitude3.jpg" alt=""
              :style="{ height: isMobile ? 'auto' : '500px', width: '800px', border: '1px dashed rgb(148, 145, 145)' }">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/aboutImages/aptitude4.jpg" alt=""
              :style="{ height: isMobile ? 'auto' : '500px', width: '800px', border: '1px dashed rgb(148, 145, 145)' }">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/aboutImages/aptitude5.jpg" alt=""
              :style="{ height: isMobile ? 'auto' : '500px', width: '800px', border: '1px dashed rgb(148, 145, 145)' }">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.initSwiper();
    /* 资质证书轮播切换为移动端 */
    this.checkIfMobile(); // 检查页面加载时是否为移动端
    window.addEventListener('resize', this.checkIfMobile); // 监听窗口大小变化
  },
  beforeDestroy() {
    /* 资质证书轮播切换为移动端 */
    window.removeEventListener('resize', this.checkIfMobile); // 组件销毁前移除事件监听
  },
  methods: {
    /* 资质证书轮播切换为移动端 */
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // 根据窗口宽度判断是否为移动端
    },
    initSwiper() {
      new Swiper('.swiper-container', {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // breakpoints: {
        //   // 当窗口宽度小于等于767像素时，只显示一个轮播项
        //   767: {
        //     slidesPerView: 1,
        //     spaceBetween: 10,
        //   },
        //   // 当窗口宽度大于767像素时，显示两个轮播项
        //   768: {
        //     slidesPerView: 2,
        //     spaceBetween: 20,
        //   },
        // },
      });
    },
  },
};
</script>

<style scoped lang="less">
/* 移动端样式 */
@media screen and (max-width: 767px) {

  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/aboutImages/about_phone.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .con {
    text-align: center;

    .top_line {
      width: 25px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 24px auto;
    }

    .top_text {
      font-size: 24px;
      color: #999999;
    }

    .top_content {
      font-size: 24px;
      color: #010101;
    }

    .bottom_title {
      color: #0C80FF;
      font-size: 20px;
    }

    .bottom_text {
      font-size: 16px;
    }

    .container {
      display: flex;
      flex-direction: column;
    }

    .col-md-6 {
      order: 2;
    }

    .col-md-6 img {
      order: 1;
    }

    .swiper-slide {
      border: 1px dashed black;
    }

    .swiper-container {
      width: 100%;
      height: auto;
      overflow: hidden;
      /* 保证图片不溢出容器 */
    }

    .swiper-slide img {
      width: 100%;
      height: auto;
      display: block;
      /* 避免图片间隙 */
    }

  }

  .container_text {
    text-align: center;
    padding-top: 50px;

    .aptitude_text {
      font-size: 20px;
      color: #999999;
    }

    .aptitude_content {
      font-size: 14px;
      color: #010101;
    }

    .container_text_line {
      width: 20px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 0px auto 40px;
    }


  }

  .aptitude {
    .el-carousel {
      height: 310px !important;
    }

    .el-carousel__item {
      width: 100% !important;
    }

    .el-carousel__arrow {
      top: 50% !important;
      /* 调整箭头垂直偏移量，根据箭头高度调整 */
    }

    img {
      width: 100% !important;
      height: auto !important;
    }

  }

}

/* PC端样式 */
@media screen and (min-width: 768px) {

  .about {
    height: 2266px;

    .banner {
      width: 100%;
      height: 600px;
      background-image: url(../assets/aboutImages/about_banner.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .banner_text {
        width: 385px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333;
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          // 线条粗细
          border: 1px solid black;
          margin: 24px 0px;
        }

      }
    }

    .con {

      // width: 100%;
      // height: 858px;
      // background-color: #FFFFFF;
      .top_text {
        font-size: 36px;
        color: #999999;
      }

      .top_content {
        font-size: 30px;
        color: #010101;
      }

      .bottom {
        margin-top: 122px;

        .bottom_text {
          font-size: 16px;
        }

        .bottom_title {
          color: #0C80FF;
          font-size: 25px;
        }
      }

      img {
        border: 1px dashed #999999;
      }

      .top_line {
        width: 25px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }
    }

    .aptitude {
      width: 100%;
      height: 800px;
      background-color: #DCE8FC;

      .swiper-slide {
        border: 1px dashed rgb(115, 114, 114);
      }

      .swiper-slide img {
        width: 100%;
        height: auto;
        display: block;
        /* 避免图片间隙 */
      }

      .container_text {
        text-align: center;
        padding-top: 50px;

        .aptitude_text {
          font-size: 36px;
          color: #999999;
        }

        .aptitude_content {
          font-size: 30px;
          color: #010101;
        }

        .container_text_line {
          width: 25px;
          height: 2px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          // 线条粗细
          border: 1px solid black;
          margin: 0px auto 40px;
        }


      }

      .el-carousel {
        padding-left: 150px;
        padding-right: 150px;
      }
    }
  }

  /deep/ .el-carousel__indicators {
    left: unset;
    transform: unset;
    right: 44%;
    bottom: 0%;
  }

  /deep/ .el-carousel__button {
    background: #908e8e;
    opacity: 1;
  }

  /deep/ .is-active .el-carousel__button {
    background: #14A5FE;
    opacity: 1;
  }

}
</style>


